import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    document.addEventListener("turbo:frame-load", this.updateLinks);
    this.updateLinks();
  }

  updateLinks = () => {
    this.linkTargets.forEach(this.updateLink);
  };

  updateLink = (element) => {
    const baseURL = new URL(window.location.href);
    const elementURL = new URL(element.href);

    if (element.dataset.overwritePathname === "true") {
      baseURL.pathname = elementURL.pathname;
    }

    elementURL.searchParams.forEach((value, key) => {
      baseURL.searchParams.set(key, value);
    });

    element.href = baseURL.toString();
  };

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.updateLinks);
  }
}
