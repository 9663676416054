/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import isEmpty from 'lodash/isEmpty';
import { handleTriggerToolTip, getHighestDataSetPoint } from './chart';
import AccessibilityController from './insights/accessibility';
import FilterController from './insights/filter';
import DataController from './insights/data';
import ChartOptions from './insights/chart_options';

Chart.register(annotationPlugin, zoomPlugin);

export default class extends Controller {
  static targets = ['canvas', 'filterBtn', 'leftBtn', 'rightBtn'];

  static values = {
    data: Object,
    activeHourBgColor: { type: String, default: '' },
    inactiveHourBgColor: { type: String, default: '' },
    incomingHourBgColor: { type: String, default: '' },
    hoverBgColor: { type: String, default: '' },
    noDataLabel: { type: String, default: ''},
    canFilter: { type: Boolean, default: false },
    disabledBgColor: { type: String, default: '' },
    dataSetsBgColor: { type: Array, default: [] },
    dataSetsInactiveHourBgColor: { type: Array, default: [] },
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;

    this.chartData = new DataController({
      dataSetsBgColorValue: this.dataSetsBgColorValue,
      dataSetsInactiveHourBgColorValue: this.dataSetsInactiveHourBgColorValue,
      hoverBgColorValue: this.hoverBgColorValue,
      activeHourBgColorValue: this.activeHourBgColorValue,
      inactiveHourBgColorValue: this.inactiveHourBgColorValue,
      incomingHourBgColorValue: this.incomingHourBgColorValue,
      dataValue: this.dataValue,
    }).populateGraphData();

    this.isMultipleDataSets = this.chartData.datasets.length > 1;

    // Check if we have any data to display
    const hasData = this.chartData.datasets.some(dataset => dataset.data && dataset.data.length > 0);

    // If no data, display empty chart with message
    if (!hasData) {
      this.chart = new Chart(element.getContext('2d'), {
        type: 'bar',
        data: this.chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: this.noDataLabelValue,
              font: {
                size: 16
              }
            },
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: false
            },
            y: {
              display: false
            }
          }
        }
      });
      return;
    }

    // Continue with normal chart initialization
    this.chart = new Chart(element.getContext('2d'), {
      type: 'bar',
      parsing: false,
      normalized: true,
      data: this.chartData,
      options: ChartOptions({
        dataPoints: this.dataValue,
        isMultipleDataSets: this.isMultipleDataSets,
        dataSetBounds: this.dataSetBounds,
        highestDataPoint: getHighestDataSetPoint(this.dataValue),
      }),
    });

    this.initDependencies();
    this.chart.canvas.style.touchAction = "pan-y";
  }

  disconnect() {
    // handle webkit memory leak
    this.chart.canvas.width = 0;
    this.chart.canvas.height = 0;

    const ctx = this.chart.canvas.getContext('2d');
    if (ctx) ctx.clearRect(0, 0, 1, 1);

    this.chart.destroy();
    this.chart = undefined;
  }

  initDependencies() {
    // Check if we have any data to display
    const hasData = this.chartData.datasets.some(dataset => dataset.data && dataset.data.length > 0);

    // Skip initialization if there's no data
    if (!hasData) {
      return;
    }

    this.accessibilityHandler = new AccessibilityController({
      leftBtnTarget: this.leftBtnTarget,
      rightBtnTarget: this.rightBtnTarget,
      dataValue: this.dataValue,
      chart: this.chart,
      dataSetBounds: this.dataSetBounds,
    });

    this.filterHandler = new FilterController({
      chart: this.chart,
      xAxisPoints: this.xAxisPoints,
      dataSetsBgColorValue: this.dataSetsBgColorValue,
      dataSetsInactiveHourBgColorValue: this.dataSetsInactiveHourBgColorValue,
      hoverBgColorValue: this.hoverBgColorValue,
      filterBtnTargets: this.filterBtnTargets,
      chartData: this.chartData,
      hasFilterBtnTarget: this.hasFilterBtnTarget,
      disabledBgColorValue: this.disabledBgColorValue,
    });

    if (this.hasFilterBtnTarget && this.filterHandler.filteredValue) {
      this.filterHandler.handleOnLoadFilter();
    } else {
      handleTriggerToolTip({
        xAxisPoints: this.xAxisPoints,
        chart: this.chart,
      });
      this.chart.update();
    }
  }

  get xAxisPoints() {
    if (isEmpty(this.dataValue.datasets) ||
      this.dataValue.datasets[0].data.length === 0) {
      return [];
    }

    return this.isMultipleDataSets
      ? this.chartData.labels
      : this.dataValue.datasets[0].data.map((el) => el.x);
  }

  filter({ params: { attribute }, currentTarget }) {
    this.filterHandler.handleFilter({ attribute, currentTarget });
  }

  scroll({ params: { direction } }) {
    this.accessibilityHandler.scroll({ direction });
  }

  get dataSetBounds() {
    const isMultipleDataSets = this.dataValue.datasets.length > 1;

    const hasData = this.dataValue.datasets.length > 0 &&
      this.dataValue.datasets[0].data.length > 0;

    let firstPoint = null;
    let lastPoint = null;

    if (hasData) {
      firstPoint = isMultipleDataSets
        ? this.dataValue.labels[0]
        : this.dataValue.datasets[0].data[0].x;

      lastPoint = isMultipleDataSets
        ? this.dataValue.labels.slice(-1)[0]
        : this.dataValue.datasets[0].data.slice(-1)[0].x;
    } else if (isMultipleDataSets && this.dataValue.labels.length > 0) {
      // If we have labels but no data
      firstPoint = this.dataValue.labels[0];
      lastPoint = this.dataValue.labels.slice(-1)[0];
    }

    return {
      firstPoint,
      lastPoint,
    };
  }
}
