import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["createdAtSpan"];

  connect() {
    this.updateWaitTimers();
    this.updateWaitTimersInterval = setInterval(this.updateWaitTimers, 1000);
  }

  updateWaitTimers = () => {
    this.createdAtSpanTargets.forEach((createdAtDiv) => {
      const createdAtMs = createdAtDiv.dataset.createdAt * 1000;
      const waitMs = new Date() - new Date(createdAtMs);
      const waitTime = msToTime(waitMs);

      createdAtDiv.innerHTML = waitTime;
    });
  };

  disconnect() {
    clearInterval(this.updateWaitTimersInterval);
  }
}

function msToTime(ms) {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor((ms / 1000 / 60 / 60));

  const hoursTime = hours === 0 ? "" : `${hours}:`;
  const minutesTime = minutes < 10 ? `0${minutes}:` : `${minutes}:`;
  const secondsTime = seconds < 10 ? `0${seconds}` : seconds;

  return `${hoursTime}${minutesTime}${secondsTime}`;
}
