import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "radio"];

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const isSelected = this.radioTargets.some(radio => radio.checked);
    this.submitButtonTarget.disabled = !isSelected;
  }

  checkSelection() {
    this.toggleSubmitButton();
  }
}