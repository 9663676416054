import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["holder", "block", "inputToReset"];

  static values = { initialSelected: Boolean, inputReset: String };

  connect() {
    if (this.initialSelectedValue) {
      this.blockTarget.classList.remove("is-hidden");
      this.holderTarget.querySelector('input[type="checkbox"]').checked = true;
    }
  }

  toggle(event) {
    event.target.classList.toggle('is-open');
    this.blockTarget.classList.toggle('is-hidden');
    if (!event.target.checked) {
      this.resetInputs();
    }
  }

  resetInputs() {
    this.inputToResetTargets.forEach((input) => {
      input.value = this.inputResetValue;
      input.dispatchEvent(new Event("change"));
    });
  }

  toggleAttr(event) {
    const inputs = this.blockTarget.querySelectorAll(`${event.target.dataset.toggleBlockToggleAttrElem}`)

    inputs.forEach((el) => {
      el.toggleAttribute(`${event.target.dataset.toggleBlockToggleAttr}`)
    })
  }

  toggleRadio(event) {

    this.blockTarget.classList.toggle('is-hidden', event.target.value === "false" && event.target.checked);
    if (event.target.dataset.toggleBlockToggleAttr) {
      const inputs = this.blockTarget.querySelectorAll(`${event.target.dataset.toggleBlockToggleAttrElem}`)

      inputs.forEach((el) => {
        el.toggleAttribute(`${event.target.dataset.toggleBlockToggleAttr}`, event.target.value === "true" && event.target.checked)
      })
    }
  }
}
