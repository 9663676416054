import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

const normalizeKeys = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export default class extends Controller {
  track(event) {
    const { params } = event;

    if (!params || params.eventName === undefined) {
      // Need to pass eventName as param
      return;
    }

    const { eventName } = params;

    const properties = Object.keys(event.params)
      .filter((key) => key !== "eventName")
      .reduce((acc, key) => {
        acc[normalizeKeys(key)] = event.params[key];
        return acc;
      }, {});

    ahoy.track(eventName, properties);
  }
}
