import { Controller } from "@hotwired/stimulus";

function createField(value, key) {
  const paramField = document.createElement("input");
  paramField.type = "hidden";
  paramField.name = key;
  paramField.value = value;
  paramField.className = "link_updater_field";
  return paramField;
}

export default class extends Controller {
  static targets = ["form"];

  connect() {
    document.addEventListener("turbo:frame-load", this.updateForms);
    this.updateForms();
  }

  updateForms = () => {
    this.formTargets.forEach(this.updateForm);
  };

  updateForm = (form) => {
    const baseURL = new URL(window.location.href);
    form.action = baseURL.pathname;

    form.querySelectorAll(".link_updater_field").forEach((el) => el.remove());

    baseURL.searchParams.forEach((value, key) => {
      if (key.startsWith("filter")) { return; }
      form.appendChild(createField(value, key));
    });
  };

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.updateForms);
  }
}
