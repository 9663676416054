import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    geoJsons: Object,
  };

  static targets = ["map"];

  connect() {
    this.priceTags = {};
    this.zoneFeatures = {}; // Store references to zone features
    this.selectedFeature = null; // Track the currently selected feature

    this.element.mapController = this;
    if (typeof google !== "undefined") {
      this.initializeMap();
    }

    // Set up global event listener for turbo frame loads
    document.addEventListener("turbo:frame-load", (event) => {
      const frame = event.target;
      // Check if this is a zone prices frame
      if (frame.id && frame.id.startsWith("zone_prices_")) {
        const zoneId = frame.id.replace("zone_prices_", "");
        // Show the content
        const zoneDiv = frame.querySelector(`[data-zone-id="${zoneId}"]`);
        if (zoneDiv) {
          // Hide all other zone divs first
          document.querySelectorAll("[data-zone-id]").forEach((el) => {
            if (el !== zoneDiv) {
              el.classList.add("hidden");
              el.classList.remove("visible-after-load");
            }
          });

          // Show this zone div
          zoneDiv.classList.remove("hidden");
          zoneDiv.classList.add("visible-after-load");

          // Filter by selected price group
          const selectedPriceGroup = document.getElementById("price-group-select").value.toLowerCase();
          const fixedPrices = zoneDiv.querySelectorAll(".fixed-price");
          fixedPrices.forEach((fixedPrice) => {
            fixedPrice.classList.add("hidden");
            const priceGroupNameEl = fixedPrice.querySelector(".price-group-name>div");
            if (priceGroupNameEl) {
              const priceGroupName = priceGroupNameEl.textContent.trim().toLowerCase();
              if (priceGroupName === selectedPriceGroup) {
                fixedPrice.classList.remove("hidden");
              }
            }
          });

          // Now that we have loaded the price data, create or update the price tag
          this.createOrUpdatePriceTag(zoneId);
        }
      }
    });
  }

  initializeMap() {
    const osloCenter = { lat: 59.926356831354106, lng: 10.774354276892957 };
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 10,
      center: osloCenter,
      disableDefaultUI: true,
    });

    // Setup map with GeoJSON features
    this.setupMapWithGeoJson();
    this.addListeners();
  }

  setupMapWithGeoJson() {
    // Add the GeoJSON data to the map
    this.map.data.addGeoJson(this.geoJsonsValue);

    // Store references to features for later use
    this.map.data.forEach((feature) => {
      const zoneId = feature.getProperty("Id");
      this.zoneFeatures[zoneId] = feature;
    });
  }

  createOrUpdatePriceTag(zoneId) {
    // Check if we already have a price tag for this zone
    if (this.priceTags[zoneId]) {
      // Update the existing price tag
      this.updatePriceTag(zoneId);
    } else {
      // Create a new price tag
      const feature = this.zoneFeatures[zoneId];
      if (!feature) return;

      // Calculate the center of the zone polygon
      const geometry = feature.getGeometry();
      const bounds = new google.maps.LatLngBounds();

      // For polygons, we need to get all points to calculate center
      geometry.forEachLatLng((latLng) => {
        bounds.extend(latLng);
      });

      const center = bounds.getCenter();

      // Get the price to show
      const price = this.getPriceForZone(zoneId);

      // Create the marker with price label
      const background = {
        url: "/price-bg.png",
        scaledSize: new google.maps.Size(64, 24),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      };

      const markerLabel = {
        text: price ? `${price} kr` : "...", // Show placeholder if no price found yet
        fontWeight: "bold",
      };

      this.priceTags[zoneId] = new google.maps.Marker({
        position: center,
        label: markerLabel,
        map: this.map,
        icon: background,
      });

      // Add click listener to price tag
      this.priceTags[zoneId].addListener("click", () => {
        this.loadZonePrices(zoneId);
      });

      // If we didn't have a price initially, try again after a short delay
      // This helps in case the price data is loaded slightly after the frame
      if (!price) {
        setTimeout(() => {
          this.updatePriceTag(zoneId);
        }, 500);
      }
    }
  }

  updatePriceTag(zoneId) {
    const priceTag = this.priceTags[zoneId];
    if (!priceTag) return;

    const price = this.getPriceForZone(zoneId);

    const label = priceTag.getLabel();
    label.text = price ? `${price} kr` : "...";
    priceTag.setLabel(label);

    // If we still don't have a price, try again after a short delay
    // This helps in case the price data is still loading
    if (!price) {
      setTimeout(() => {
        const newPrice = this.getPriceForZone(zoneId);
        if (newPrice) {
          const newLabel = priceTag.getLabel();
          newLabel.text = `${newPrice} kr`;
          priceTag.setLabel(newLabel);
        }
      }, 1000);
    }
  }

  getPriceForZone(zoneId) {
    // Get the selected price group
    const selectedPriceGroup = document.getElementById("price-group-select").value;

    // First try to find the price using the data attributes
    let priceElement = document.querySelector(`.price-to-show[data-zone="${zoneId}"][data-price-group="${selectedPriceGroup}"]`);

    // If not found, try to find it by searching in the loaded zone data
    if (!priceElement) {
      // Look for price in the zone's loaded data
      const zoneDiv = document.querySelector(`[data-zone-id="${zoneId}"]`);
      if (zoneDiv) {
        // Find morning rush hour price (typically 6-8AM)
        const possiblePriceElements = Array.from(zoneDiv.querySelectorAll("input.price-to-show"));
        if (possiblePriceElements.length > 0) {
          priceElement = possiblePriceElements[0]; // Use the first one found
        } else {
          // If no specific tagged element, look for any price_4_pas input as fallback
          const priceGroups = zoneDiv.querySelectorAll(".fixed-price");

          // Find the visible price group (matching the selected one)
          for (const priceGroup of priceGroups) {
            const priceGroupNameEl = priceGroup.querySelector(".price-group-name>div");
            if (priceGroupNameEl && priceGroupNameEl.textContent.trim().toLowerCase() === selectedPriceGroup.toLowerCase()) {
              // This is the matching price group, find the price_4_pas input
              const priceInput = priceGroup.querySelector("input[name=\"prices[price_4_pas]\"]");
              if (priceInput) {
                priceElement = priceInput;
                break;
              }
            }
          }
        }
      }
    }

    return priceElement ? priceElement.value : null;
  }

  addListeners() {
    const self = this;
    this.map.data.addListener("mouseover", function (event) {
      // Don't change the style of the selected feature when hovering
      if (self.selectedFeature === event.feature) return;
      
      this.map.data.revertStyle();
      
      // Keep the selected feature styled if there is one
      if (self.selectedFeature) {
        this.map.data.overrideStyle(self.selectedFeature, {
          strokeWeight: 4,
          fillColor: "blue",
        });
      }
      
      this.map.data.overrideStyle(event.feature, {
        strokeWeight: 6,
        fillColor: "green",
      });
    });

    this.map.data.addListener("mouseout", function () {
      this.map.data.revertStyle();
      
      // Keep the selected feature styled if there is one
      if (self.selectedFeature) {
        this.map.data.overrideStyle(self.selectedFeature, {
          strokeWeight: 4,
          fillColor: "blue",
        });
      }
    });

    this.map.data.addListener("click", (event) => {
      // The 'Id' property is set in the GeoJSON feature in Zone.collection_to_geo_json
      const zoneId = event.feature.getProperty("Id");
      
      // Set this as the selected feature
      if (this.selectedFeature !== event.feature) {
        this.selectedFeature = event.feature;
        
        // Reset all styles and then highlight the selected one
        this.map.data.revertStyle();
        this.map.data.overrideStyle(this.selectedFeature, {
          strokeWeight: 4,
          fillColor: "blue",
        });
      }
      
      self.loadZonePrices(zoneId);
    });
  }

  loadZonePrices(zoneId) {
    // Hide all price tables first
    this.hidePriceTables();

    // Make sure any existing zone content is hidden
    document.querySelectorAll("[data-zone-id]").forEach((el) => {
      el.classList.add("hidden");
      el.classList.remove("visible-after-load");
    });

    // Get the container where we'll put zone prices
    const container = document.getElementById("zone-prices-container");
    if (!container) return;

    // Check if we already have a frame for this zone
    const frameId = `zone_prices_${zoneId}`;
    let frame = document.getElementById(frameId);

    if (!frame) {
      // Frame doesn't exist yet, create it
      frame = document.createElement("turbo-frame");
      frame.id = frameId;

      // Get current URL path parts to construct the src
      const pathParts = window.location.pathname.split("/");
      const centralId = pathParts[pathParts.indexOf("centrals") + 1];

      // Get the pickup location from the select
      const pickupLocationSelect = document.querySelector("select[name=\"pickup_location\"]");
      const pickupLocation = pickupLocationSelect ? pickupLocationSelect.value : "osl_airport"; // Default fallback

      // Set the src to load zone prices with the correct route format
      frame.src = `/backoffice/centrals/${centralId}/fixed_prices/zone/${zoneId}?pickup_location=${pickupLocation}&format=turbo_stream`;

      // Add loading indicator
      const loadingIndicator = document.createElement("div");
      loadingIndicator.className = "animate-pulse bg-gray-100 rounded-lg p-4 mb-4";
      loadingIndicator.innerHTML = `
        <div class="h-8 bg-gray-200 rounded w-1/4 mb-4">${document.querySelector("html").getAttribute("lang") === "no" ? "Laster..." : "Loading..."}</div>
        <div class="space-y-3">
          <div class="h-4 bg-gray-200 rounded w-full"></div>
          <div class="h-4 bg-gray-200 rounded w-full"></div>
          <div class="h-4 bg-gray-200 rounded w-full"></div>
        </div>
      `;

      frame.appendChild(loadingIndicator);

      // Add the frame to the container
      container.appendChild(frame);

      // Set the frame to load eagerly
      frame.setAttribute("loading", "eager");
      
      // Create price tag with default value on first click - don't wait for frame to load
      this.createOrUpdatePriceTag(zoneId);
    } else {
      // Frame already exists, so just make its content visible if it's loaded
      const zoneDiv = frame.querySelector(`[data-zone-id="${zoneId}"]`);
      if (zoneDiv) {
        zoneDiv.classList.remove("hidden");
        zoneDiv.classList.add("visible-after-load");

        // Filter the rows by selected price group
        const selectedPriceGroup = document.getElementById("price-group-select").value.toLowerCase();
        const fixedPrices = zoneDiv.querySelectorAll(".fixed-price");
        fixedPrices.forEach((fixedPrice) => {
          fixedPrice.classList.add("hidden");
          const priceGroupNameEl = fixedPrice.querySelector(".price-group-name>div");
          if (priceGroupNameEl) {
            const priceGroupName = priceGroupNameEl.textContent.trim().toLowerCase();
            if (priceGroupName === selectedPriceGroup) {
              fixedPrice.classList.remove("hidden");
            }
          }
        });

        // Update price tag since we're showing it again
        this.createOrUpdatePriceTag(zoneId);
      } else {
        // Content might still be loading, force eager loading
        frame.setAttribute("loading", "eager");
        
        // Ensure price tag is visible even if frame is still loading
        this.createOrUpdatePriceTag(zoneId);
      }
    }
  }

  hidePriceTables() {
    // Find all content with data-zone-id and hide them
    document.querySelectorAll("[data-zone-id]").forEach((node) => {
      node.classList.add("hidden");
      node.classList.remove("visible-after-load");
    });

    // Also look within all turbo frames
    document.querySelectorAll("turbo-frame").forEach((frame) => {
      const zoneContent = frame.querySelector("[data-zone-id]");
      if (zoneContent) {
        zoneContent.classList.add("hidden");
        zoneContent.classList.remove("visible-after-load");
      }
    });
  }

  // Method to handle price updates from price_update_controller
  updatePrice(zoneId, newPrice) {
    // Update the price tag on the map with the new price
    this.updatePriceTag(zoneId);
  }

  updatePriceTagsWithPriceGroup() {
    // Only update price tags for zones that have been loaded
    Object.keys(this.priceTags).forEach((zoneId) => {
      this.updatePriceTag(zoneId);
    });

    // Also update the visible price table to show the correct price group rows
    document.querySelectorAll("[data-zone-id]:not(.hidden)").forEach((zoneDiv) => {
      const zoneId = zoneDiv.getAttribute("data-zone-id");
      if (zoneId) {
        const selectedPriceGroup = document.getElementById("price-group-select").value.toLowerCase();
        const fixedPrices = zoneDiv.querySelectorAll(".fixed-price");
        fixedPrices.forEach((fixedPrice) => {
          fixedPrice.classList.add("hidden");
          const priceGroupNameEl = fixedPrice.querySelector(".price-group-name>div");
          if (priceGroupNameEl) {
            const priceGroupName = priceGroupNameEl.textContent.trim().toLowerCase();
            if (priceGroupName === selectedPriceGroup) {
              fixedPrice.classList.remove("hidden");
            }
          }
        });
      }
    });
  }
}
