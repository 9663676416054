import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "results", "streetNumber", "details", "options", "option", "inputContainer"]
  static values = {
    open: { type: Boolean, default: false },
    activeIndex: { type: Number, default: -1 }
  }

  connect() {
    this.clearResults()
    this.setupLoadingElement()

    this.outsideClickHandler = this.closeOnClickOutside.bind(this)
    document.addEventListener("click", this.outsideClickHandler)

    this.updateAriaAttributes()
  }

  disconnect() {
    document.removeEventListener("click", this.outsideClickHandler)
  }

  close() {
    this.openValue = false
  }

  resultsTargetConnected() {
    this.openValue = true
  }

  resultsTargetDisconnected() {
    this.openValue = false
  }

  closeOnClickOutside(event) {
    if (this.openValue && !this.element.contains(event.target)) {
      this.close()
    }
  }

  keydown(event) {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        this.openValue = true
        this.moveActive(1)
        break
      case "ArrowUp":
        event.preventDefault()
        this.openValue = true
        this.moveActive(-1)
        break
      case "Home":
        event.preventDefault()
        if (this.openValue && this.hasOptionTarget) {
          this.activeIndexValue = 0
        }
        break
      case "End":
        event.preventDefault()
        if (this.openValue && this.hasOptionTarget) {
          this.activeIndexValue = this.optionTargets.length - 1
        }
        break
      case "Enter":
        if (this.openValue && this.activeIndexValue >= 0) {
          event.preventDefault()
          this.selectOption(this.optionTargets[this.activeIndexValue])
        }
        break
      case "Escape":
        if (this.openValue) {
          event.preventDefault()
          this.close()
          this.inputTarget.focus()
        }
        break
      case "Tab":
        break
    }
  }

  moveActive(step) {
    if (!this.hasOptionTarget) return

    const visibleOptions = this.optionTargets
    if (visibleOptions.length === 0) return

    let newIndex

    if (this.activeIndexValue === -1) {
      newIndex = step > 0 ? 0 : visibleOptions.length - 1
    } else {
      const currentActiveOption = this.optionTargets[this.activeIndexValue]
      const currentVisibleIndex = visibleOptions.indexOf(currentActiveOption)

      if (currentVisibleIndex === -1) {
        newIndex = step > 0 ? 0 : visibleOptions.length - 1
      } else {
        newIndex = (currentVisibleIndex + step + visibleOptions.length) % visibleOptions.length
      }
    }

    this.activeIndexValue = this.optionTargets.indexOf(visibleOptions[newIndex])
  }

  selectOption(option) {
    const button = option.querySelector('button.pac-item__button');
    if (button) {
      button.form.requestSubmit(button)
      this.close()
    }
  }

  optionMouseover(event) {
    const option = event.currentTarget
    this.activeIndexValue = this.optionTargets.indexOf(option)
  }

  updateDropdown() {
    if (this.openValue) {
      if (this.hasResultsTarget) {
        this.resultsTarget.classList.remove("hidden")
      }
      this.inputTarget.setAttribute("aria-expanded", "true")
      this.element.classList.add("active")
    } else {
      if (this.hasResultsTarget) {
        this.resultsTarget.classList.add("hidden")
      }
      this.inputTarget.setAttribute("aria-expanded", "false")
      this.element.classList.remove("active")
      this.activeIndexValue = -1
    }

    this.updateAriaAttributes()
  }

  updateAriaAttributes() {
    // Set aria-activedescendant on input if there's an active option
    if (this.activeIndexValue >= 0 && this.activeIndexValue < this.optionTargets.length) {
      const activeOption = this.optionTargets[this.activeIndexValue]
      const id = activeOption.id || `${this.element.id}-option-${this.activeIndexValue}`
      activeOption.id = id
      this.inputTarget.setAttribute("aria-activedescendant", id)
    } else {
      this.inputTarget.removeAttribute("aria-activedescendant")
    }

    // Update ARIA selected state on options
    this.optionTargets.forEach((option, index) => {
      if (index === this.activeIndexValue) {
        option.setAttribute("aria-selected", "true")
        option.classList.add("active")
      } else {
        option.setAttribute("aria-selected", "false")
        option.classList.remove("active")
      }
    })
  }

  // Observe value changes
  activeIndexValueChanged() {
    this.updateAriaAttributes()
  }

  openValueChanged() {
    this.updateDropdown()
  }

  setupLoadingElement() {
    this.loadingElement = document.querySelector('.bf-search__loading')
  }

  search() {
    const query = this.inputTarget.value.trim()

    if (query.length === 0) {
      this.clearResults()
      return
    }

    this.showLoading()
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.inputTarget.form.requestSubmit()
    }, 300)
  }

  clearResults() {
    if (this.hasResultsTarget) {
      this.resultsTarget.innerHTML = ""
    }
  }

  clearSearch() {
    if (this.hasDetailsTarget) {
      this.detailsTarget.innerHTML = ""
    }

    if (this.hasInputTarget) {
      this.inputTarget.value = ""
    }
  }

  clearStreetNumberSearch() {
    if (this.hasStreetNumberTarget) {
      this.streetNumberTarget.value = ""
    }
  }

  showLoading() {
    if (this.loadingElement) {
      this.loadingElement.classList.remove('hidden')
    }
  }

  hideLoading() {
    if (this.loadingElement) {
      this.loadingElement.classList.add('hidden')
    }
  }

  focusContainer() {
    this.inputContainerTarget.classList.add("is-focus");
    if (this.hasResultsTarget && this.resultsTarget.innerHTML.trim() !== "") {
      this.openValue = true;
    }
  }

  blurContainer() {
    this.inputContainerTarget.classList.remove("is-focus");
  }
}
