import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropDown", "checkBox", "taximeterFactor"];

  static values = { confirmationMessage: String };

  connect() {
    this.element.priceGroupController = this;
    this.mapController = document.getElementById("map").mapController;
    this.fixedPrices = document.querySelectorAll(".fixed-price");
    this.filterBySelection();
  }

  filterBySelection() {
    // Get all visible zone tables
    const visibleZoneTables = document.querySelectorAll('[data-zone-id]:not(.hidden)');

    visibleZoneTables.forEach(zoneTable => {
      const fixedPrices = zoneTable.querySelectorAll('.fixed-price');
      fixedPrices.forEach(fixedPrice => {
        fixedPrice.classList.add("hidden");
        if (fixedPrice.querySelector(".price-group-name>div").textContent.trim().toLowerCase() === this.dropDownTarget.value.toLowerCase()) {
          fixedPrice.classList.remove("hidden");
        }
      });
    });

    this.showOptions();
    this.mapController.updatePriceTagsWithPriceGroup();
  }

  showOptions() {
    this.element.querySelectorAll(".price-group-options[data-price-group]").forEach((option) => { option.classList.add("hidden"); });
    const options = this.element.querySelectorAll(`.price-group-options[data-price-group="${this.dropDownTarget.value}"]`);
    options.forEach((option) => { option.classList.remove("hidden"); });
  }

  submit(event) {
    event.currentTarget.form.requestSubmit();
  }
}
