import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.focusField()
  }

  focusField() {
    if (this.hasFieldTarget) {
      // Short timeout to ensure DOM is ready
      setTimeout(() => {
        this.fieldTarget.focus()
      }, 300)
    }
  }
}
