import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["stream"]

    streamTargetConnected(){
        const url = new URL(location);
        const queueType = url.searchParams.get("queue") || "all"

        const selectorElement = document.getElementById("queue_select")
        const selectedSelectorElement = Array.from(selectorElement.children).find((element) => {
            return element.value === queueType
        })
        selectedSelectorElement.selected = "selected"

        const tabsElement = document.getElementById("queue_tabs")
        const selectedTabsElement = Array.from(tabsElement.children).find((element) => {
            return element.id === `depot_status_${queueType}`
        })
        selectedTabsElement.classList.add("fl-tab_active")
    }
}
