import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    const optionsLength = this.element.options?.length || 0;

    new SlimSelect({
      select: this.element,
      cssClasses: {
        main: "ss-multi__select",
      },
      events: {
        afterChange: () => {
          this.element.closest("form").requestSubmit();
        },
      },
      settings: {
        maxValuesShown: 2,
        placeholderText: "All",
        allowDeselect: true,
        showSearch: optionsLength > 10,
        closeOnSelect: false,
      },
    });
  }
}
